<template>
    <div class="content" :class="{'hui': resData.ishui == 'Y'}">
        <div class="header-box">
            <div class="header-div">
                <div class="header-top">
                    <img src="static/jr/logo2.png" alt="" class="logo">
                </div>
            </div>
         </div>
        <div class="top-img">
            <div class="banner-img" v-for="(item,index) in banner" :key="index">
                <img :src="item.img" alt="">
            </div>
        </div>
        <div class="content-1366">
            <ul class="search-ul">
                <li v-for="(item,i) in search" :key="i"  @click="cookie(item)">
                    <p>{{item.title}}</p>
                    <p>{{item.ctime | timeLenght }}</p>
                </li>
            </ul>
             <div class="pagination">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    background
                    :current-page="currentPage"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="pageNum"
                    layout="total, prev, pager, next"
                    :total="total">
                </el-pagination>
            </div>
        </div>
        <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="350px">
            <p>您没有权限，请先登录！
                <el-button type="primary" style="margin-left:10px;" @click="login">登录</el-button>
            </p>
        </el-dialog>
        <Footer></Footer>
    </div>
</template>

<script>
import Footer from './components/footer'
import JrApi from '../../lvya-common/src/api/eos/jr'
import axios from 'axios'
import qs from 'qs'
export default {
    name: 'top',
     components: {
        Footer,
    },
    data() {
        return {
            banner: '',
            search: [],
            currentPage: 1, // 默认在第一页
            pageNum: 10, // 默认每页显示10条数据
            total: 0, // 默认总条数为一条
            resData: '',
            dialogVisible: false,
        }
    },
    created() {
        this.HF()
        this.urlGet()
        this.Banner()
        this.getPaging()
    },
    methods: {
        cookie(item) {
            if (item.isrole == 'Y') {
                let EOSToken = Cookies.get('EOSToken')
                if (EOSToken) {
                    this.$router.push({ path: '/searchDetails', query: { id: item.id, pid: this.pid }})
                } else {
                    this.id = item.id
                    this.dialogVisible = true
                }
            } else {
                this.$router.push({ path: '/searchDetails', query: { id: item.id, pid: this.pid }})
            }
        },
        login() {
            window.location.href = `http://jrzz.lvya.org/eos-web/#/login?url=http://www.jsjrzz.net/#/indexDetails2?id=${this.id}&pid=${this.pid}`
        },
        urlGet() {
            this.key = this.$route.query.key
            // console.log(this.key)
        },
        // banner图
        async Banner() {
            const resData = await JrApi.Banner()
            this.banner = resData
            // console.log(this.banner)
        },
        async HF() {
            const resData = await JrApi.HF()
            this.resData = resData
            // console.log(resData)
        },
        // 跳转二级页面
        // hrefUrl(item) {
        //     this.$router.push({ path: '/searchDetails', query: { id: item.id, pid: this.pid }})
        // },
        getPaging(key) {
            axios.post(window.GateWayServer + '/schoolsite/api/38/getLikeArts', qs.stringify({
                key: this.key,
                pageNo: this.currentPage,
                pageSize: this.pageNum
            }))
            .then(res => {
                this.search = res.data.data
                this.total = Number(res.data.count)
                // console.log(res.data.data)
            })
        },
        handleCurrentChange: function(currentPage) {
            this.currentPage = currentPage
            this.getPaging()
        },
        handleSizeChange(val) {
            this.pageNum = val
            this.getPaging()
        }
    },
    mounted() {
    }
}
</script>

<style scope>
.hui{
  -webkit-filter:grayscale(100%)
}
.header-box{
    width: 100%;
    background: #fff;
}
.header-div{
    width: 1200px;
    margin: 0 auto;
}
.header-top{
    height: 120px;
    display: flex;
}
.header-top img{
    width: 500px;
    height: 90px;
    margin-top: 10px;
}
.content {
  width: 100%;
}
.top-img{
  width: 100%;
  height: 410px;
  min-width: 1200px;
}
.banner-img {
    width: 100%;
    height: 410px;
    min-width: 1200px;
    background: url('../../../public/static/jr/banner2.png') center top no-repeat;
    /* background-size: 100% 100%; */
}
.top-img img{
    width: 100%;
    height: 100%;
}
.content-1366{
    width: 1200px;
    min-height: 400px;
    margin: 0 auto;
}
.search-ul{
    width: 100%;
    margin-block-start: 0em;
    margin-block-end: 0em;
    padding-inline-start: 0px;
}
.search-ul li{
    width: 100%;
    display: flex;
    justify-content: space-between;
    line-height: 50px;
    border-bottom: 1px solid #ccc;
    color: #444;
    margin-block-start: 0em;
    margin-block-end: 0em;
}
.search-ul li:hover{
    color: #bd2b17;
    cursor: pointer;
}
p{
    margin-block-start: 0em;
    margin-block-end: 0em;
}
.el-pagination.is-background .el-pager li:not(.disabled).active{
    background: #bd2b17;
}
.el-pagination{
    margin-top: 10px;
    padding-left: 0px;
    margin-bottom: 30px;
}
</style>
